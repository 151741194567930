import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyNetwork, addNetwork } from "../../domain/apis/networkService";
import _ from "lodash";
import { useEffect } from "react";
import { FormUIItem } from "../commons";
import { useState } from 'react';

function NetworkModifyComponent({ show, handleClose, data }) {
  const [formUI, setFormUI] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setError({});
  }, [show]);

  useEffect(() => {
    if (!data) {
      setFormUI({});
    } else {
      setFormUI(data);
    }
  }, [data, show]);

  const handleUpdateForm = (key, value) => {
    let obj = {};
    obj[key] = value;
    setFormUI({
      ...formUI,
      ...obj,
    })
  };

  const validateForm = () => {
    var result = true;
    var errorState = {};

    if (!formUI["name"] || formUI["name"] === '') {
      result = false;
      errorState.name = true;
    }

    setError(errorState);
    return result;
  }

  const onModify = () => {
    if (!validateForm()) {
      return;
    }

    if (_.get(formUI, "bandwidth") === '') {
      formUI.bandwidth = null;
    }

    if (data) {
      modifyNetwork(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addNetwork(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa Network</Modal.Title>}
            {!data && <Modal.Title>Thêm Network</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormUIItem
                label={"Tên"}
                code={"name"}
                error={_.get(error, "name")}
                errorMessage={"Vui lòng nhập nội dung!"}
                required
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Băng thông"}
                code={"bandwidth"}
                unit={"MBps"}
                type={"number"}
                initValue={_.get(data, "bandwidth")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"PPPOE username"}
                code={"pppoeUsername"}
                initValue={_.get(data, "pppoeUsername")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"PPPOE password"}
                code={"pppoePassword"}
                initValue={_.get(data, "pppoePassword")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"WAN IP"}
                code={"wanip"}
                initValue={_.get(data, "wanip")}
                handleUpdate={handleUpdateForm}
              />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default NetworkModifyComponent;
