import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

export default function data({ model, deleteEvent, editEvent }) {
  model = model ?? [];

  const Overall = ({ name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <div>
        <div code={"btnEdit" + id} onClick={() => editEvent(`${id}`)}>
          <MDBadge
            badgeContent="Sửa"
            color="success"
            variant="gradient"
            size="sm"
          />
        </div>
        <div style={{ height: 4 }} />
        <div
          code={"btnDelete" + id}
          onClick={() => {
            deleteEvent(`${id}`);
          }}
        >
          <MDBadge
            badgeContent="Xóa"
            color="warning"
            variant="gradient"
            size="sm"
          />
        </div>
      </div>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{id}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const RowItem = ({ value }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{value}</MDTypography>
    </MDBox>
  );

  const rows = [];

  model.forEach((item) => {
    rows.push({
      overall: <Overall name={item.fullName} id={item.id ?? 0} />,
      email: <RowItem value={item.email} />,
      position: <RowItem value={item.position} />,
    });
  });

  return {
    columns: [
      { Header: "Họ tên", accessor: "overall", width: "45%", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Chức vụ", accessor: "position", align: "left" },
    ],
    rows: rows,
  };
}
