import { Observable } from "rxjs";

export default class AxiosObservable {
  result = null;
  constructor(result) {
    this.result = result;
  }

  $observable() {
    return new Observable((subscriber) => {
      if (this.result) {
        this.result
          .then((response) => {
            subscriber.next(response);
          })
          .catch((error) => {
            subscriber.error(error);
            console.log(error);
          })
          .then(() => {
            subscriber.complete();
          });
      }
    });
  }
}

export function $observable(axiosChain) {
  let job$ = new Observable((subscriber) => {
    axiosChain
      .then((response) => {
        subscriber.next(response);
      })
      .catch((error) => {
        subscriber.error(error);
        console.log(error);
      })
      .then(() => {
        subscriber.complete();
      });
  });
  return job$;
}

export const bodyHeader = {
  "Content-Type": "application/json",
};

export const mapForm = (form) => {
  const params = new URLSearchParams();
  for (let key in form) {
    if (form.hasOwnProperty(key)) {
      params.append(key, form[key]);
    }
  }
  return params;
};
