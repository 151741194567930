// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { styles } from './styles';
import { ComboBox } from './../commons/Combobox';

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../widgets/Navbars/DashboardNavbar";
import Footer from "../../widgets/Footer";
import DataTable from "../../widgets/Tables/DataTable";
import ToastWidget from "widgets/Toast";
import {
  getCustomer,
  deleteCustomer,
  getCustomerByOrganization,
} from "../../domain/apis/customerService";
import {
  getOrganizations,
} from "../../domain/apis/organizationService";
import CustomerModifyComponent from "./customerMofifyComponent";

// Data
import renderTableData from "./data/tableData";

function Customers() {
  const [dataset, setDataset] = useState({
    columns: [],
    rows: [],
  });

  const [uiToast, setUIToast] = useState({
    isShow: false,
    message: "",
  });

  const [organizations, setOrganizations] = useState([]);
  const [organizationID, setOrganizationID] = useState();

  const [reload, setReload] = useState(0);
  const [modifyDialogShow, setModifyDialogShow] = useState({
    show: false,
    data: null,
  });
  const reloadPage = () => {
    setReload(reload + 1);
  };

  function fetchOrganizations() {
    getOrganizations().subscribe({
      next({ data }) {
        const { value } = data;
        setOrganizations(value);
      },
      error(err) {
        console.log(err);
      },
    });
  }

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    // reset
    setDataset({
      columns: [],
      rows: [],
    });

    getCustomerByOrganization(organizationID).subscribe({
      next({ data }) {
        const { value } = data;
        setDataset(
          renderTableData({
            model: value,
            deleteEvent,
            editEvent,
          })
        );
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }, [reload, organizationID]);

  function handleDelete(id) {
    deleteCustomer(id).subscribe({
      next({ data }) {
        if (data.status) {
          setUIToast({
            isShow: true,
            message: "Đã xoá!!!",
          });

          setTimeout(() => {
            reloadPage();
          }, 2000);
        }
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }

  function handleShowEdit(id) {
    getCustomer(id).subscribe({
      next({ data }) {
        setModifyDialogShow({
          show: true,
          data: data,
        });
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }

  // events
  function deleteEvent(id) {
    const choice = window.confirm("Bạn có muốn xoá?");
    if (choice) {
      handleDelete(id);
    }
  }

  function editEvent(id) {
    handleShowEdit(id);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastWidget
        key="toast"
        isShow={uiToast.isShow}
        message={uiToast.message}
        onClose={() => setUIToast({ isShow: false })}
      />
      <CustomerModifyComponent
        data={modifyDialogShow.data}
        show={modifyDialogShow.show}
        handleClose={(forceReload) => {
          setModifyDialogShow({
            show: false,
          });
          if (forceReload) {
            reloadPage();
          }
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <div style={{ textAlign: "end", paddingRight: 24 }}>
                  <Button
                    onClick={() => {
                      setModifyDialogShow({
                        show: true,
                        data: undefined,
                      });
                    }}
                    variant="contained"
                    style={{
                      color: "white",
                    }}
                  >
                    Tạo mới
                  </Button>
                </div>
                <Row style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}>
                  <Col className="col-6 col-md-4" style={{ ...styles.selfCenter, ...styles.textNormal }}>Tổ chức</Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      value={organizationID}
                      placeHolder={"--- Tất cả ---"}
                      data={organizations}
                      onItemChange={(value) => {
                        setOrganizationID(value);
                      }} />
                  </Col>
                </Row>
                <DataTable
                  table={dataset}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Customers;
