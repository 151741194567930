// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "../../widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../widgets/Navbars/DashboardNavbar";
import Footer from "../../widgets/Footer";
import DataTable from "../../widgets/Tables/DataTable";
import ToastWidget from "widgets/Toast";
import {
  getVPSsByServerId,
  deleteVPS,
  getVPS,
  stopVM,
  startVM,
  suspendVM,
} from "../../domain/apis/vpsService";
import { getServers, getVMList } from "../../domain/apis/serverService";
import VPSModifyComponent from "./vpsMofifyComponent";
import { ComboBox } from "./../commons/Combobox";
import { styles } from "./styles";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Data
import renderTableData from "./data/tableData";

function VPSs() {
  const [dataset, setDataset] = useState({
    columns: [],
    rows: [],
  });

  const [servers, setServers] = useState([]);
  const [serverID, setServerID] = useState();

  const [uiToast, setUIToast] = useState({
    isShow: false,
    message: "",
  });

  const [reload, setReload] = useState(0);
  const [modifyDialogShow, setModifyDialogShow] = useState({
    show: false,
    data: null,
  });
  const reloadPage = () => {
    setReload(reload + 1);
  };

  function fetchServers() {
    getServers().subscribe({
      next({ data }) {
        const { value } = data;
        setServers(value);
      },
      error(err) {
        console.log(err);
      },
    });
  }

  useEffect(() => {
    fetchServers();
  }, []);

  useEffect(() => {
    // reset
    setDataset({
      columns: [],
      rows: [],
    });

    getVPSsByServerId(serverID).subscribe({
      next({ data }) {
        if (!data) {
          return;
        }
        setDataset(
          renderTableData({
            model: data,
            deleteEvent,
            editEvent,
            startEvent,
            shutdownEvent,
          })
        );
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }, [reload, serverID]);

  function handleDelete(id) {
    deleteVPS(id).subscribe({
      next({ data }) {
        if (data.status) {
          setUIToast({
            isShow: true,
            message: "Đã xoá!!!",
          });

          setTimeout(() => {
            reloadPage();
          }, 2000);
        }
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }

  function handleShutdown(id) {
    stopVM(id).subscribe({
      next({ data }) {
        if (data.status) {
          setUIToast({
            isShow: true,
            message: "Đã tắt!",
          });

          setTimeout(() => {
            reloadPage();
          }, 2000);
        }
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }

  function handleStart(id) {
    startVM(id).subscribe({
      next({ data }) {
        if (data.status) {
          setUIToast({
            isShow: true,
            message: "Đã khởi động!",
          });

          setTimeout(() => {
            reloadPage();
          }, 2000);
        }
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }

  function handleShowEdit(id) {
    getVPS(id).subscribe({
      next({ data }) {
        setModifyDialogShow({
          show: true,
          data: data,
        });
      },
      error(err) {
        setUIToast({
          isShow: true,
          message: err.message,
        });
      },
    });
  }

  // events
  function deleteEvent(id) {
    const choice = window.confirm("Bạn có muốn xoá?");
    if (choice) {
      handleDelete(id);
    }
  }

  function editEvent(id) {
    handleShowEdit(id);
  }

  function shutdownEvent(id) {
    const choice = window.confirm("Xác nhận tắt?");
    if (choice) {
      handleShutdown(id);
    }
  }

  function startEvent(id) {
    const choice = window.confirm("Xác nhận khởi động?");
    if (choice) {
      handleStart(id);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastWidget
        key="toast"
        isShow={uiToast.isShow}
        message={uiToast.message}
        onClose={() => setUIToast({ isShow: false })}
      />
      <VPSModifyComponent
        data={modifyDialogShow.data}
        show={modifyDialogShow.show}
        handleClose={(forceReload) => {
          setModifyDialogShow({
            show: false,
          });
          if (forceReload) {
            reloadPage();
          }
        }}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <div style={{ textAlign: "end", paddingRight: 24 }}>
                  <Button
                    onClick={() => {
                      setModifyDialogShow({
                        show: true,
                        data: undefined,
                      });
                    }}
                    variant="contained"
                    style={{
                      color: "white",
                    }}
                  >
                    Tạo mới
                  </Button>
                </div>
                <Row
                  style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}
                >
                  <Col
                    className="col-6 col-md-4"
                    style={{ ...styles.selfCenter, ...styles.textNormal }}
                  >
                    Máy chủ
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      value={serverID}
                      placeHolder={"--- Tất cả ---"}
                      data={servers}
                      onItemChange={(value) => {
                        setServerID(value);
                      }}
                    />
                  </Col>
                </Row>
                <DataTable
                  table={dataset}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VPSs;
