import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyServer, addServer } from "../../domain/apis/serverService";
import _, { result } from "lodash";
import { useEffect } from "react";
import { FormUIItem } from '../commons';
import { styles } from './styles';
import Row from "react-bootstrap/Row";
import FormControl from "@mui/material/FormControl";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import { ComboBox } from '../commons/Combobox';

function ServerModifyComponent({ show, handleClose, data, networks }) {
  const [formUI, setFormUI] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    setError({});
  }, [show]);

  useEffect(() => {
    if (!data) {
      setFormUI({});
    } else {
      setFormUI(data);
    }
  }, [data, show]);

  const handleUpdateForm = (key, value) => {
    let obj = {};
    obj[key] = value;
    setFormUI({
      ...formUI,
      ...obj,
    })
  };

  const validateForm = () => {
    var result = true;
    var errorState = {};
    if (!formUI["networkID"] || formUI["networkID"] === null || formUI["networkID"] === 'none') {
      result = false;
      errorState.network = true;
    }

    if (!formUI["name"] || formUI["name"] === '') {
      result = false;
      errorState.name = true;
    }

    if (!formUI["code"] || formUI["code"] === '') {
      result = false;
      errorState.code = true;
    }

    setError(errorState);
    return result;
  }

  const onModify = () => {

    if (!validateForm()) {
      return;
    }

    if (data) {
      modifyServer(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addServer(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa Server</Modal.Title>}
            {!data && <Modal.Title>Thêm Server</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Row>
                  <Col className="col-6 col-md-4" style={{ ...styles.selfCenter, ...styles.textNormal }}>Network*</Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      error={_.get(error, "network")}
                      errorMessage={"Vui lòng chọn nội dung!"}
                      value={formUI["networkID"]}
                      data={networks}
                      placeHolder={"--- Chọn Network ---"}
                      onItemChange={(value) => {
                        handleUpdateForm("networkID", value);
                      }} />
                  </Col>
                </Row>
              </FormControl>
              <FormUIItem
                label={"Tên"}
                error={_.get(error, "name") === true}
                errorMessage={"Vui lòng nhập nội dung!"}
                code={"name"}
                required
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Mã Máy"}
                error={_.get(error, "code") === true}
                errorMessage={"Vui lòng nhập nội dung!"}
                code={"code"} required
                initValue={_.get(data, "code")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Hãng server"}
                code={"branch"}
                initValue={_.get(data, "branch")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Thông số kỹ thuật"}
                code={"specification"}
                initValue={_.get(data, "specification")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"CPU CORE"}
                code={"core"}
                unit={"core"}
                type={"number"}
                initValue={_.get(data, "core")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"RAM"}
                code={"ram"}
                unit={"GB"}
                type={"number"}
                initValue={_.get(data, "ram")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Ổ đĩa cứng"}
                code={"storage"}
                unit={"GB"}
                type={"number"}
                initValue={_.get(data, "storage")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"IP"}
                code={"ip"}
                initValue={_.get(data, "ip")}
                handleUpdate={handleUpdateForm} />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ServerModifyComponent;
