import Axios from "axios";
import { bodyHeader, $observable } from "../../common/libs/AxiosObservable";

export const getVPSs = () => {
  return $observable(Axios.get("api/v1/admin/vms"));
};

/*export const getVPSsByServerId = (id) => {
  if (!id || id === "none") {
    return getVPSs();
  }
  return $observable(Axios.get(`api/v1/admin/vms?$filter=serverID eq ${id}`));
};*/

export const getVPSsByServerId = (id) => {
  if (id === "none") {
    id = undefined;
  }
  return $observable(Axios.get(`api/v1/admin/vms/${id}/list`));
};

export const getVPS = (id) => {
  return $observable(Axios.get(`api/v1/admin/vms/${id}`));
};

export const deleteVPS = (id) => {
  return $observable(Axios.delete(`api/v1/admin/vms/${id}`));
};

export const addVPS = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/vms`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyVPS = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/vms`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const startVM = (id) => {
  return $observable(
    Axios.post(
      `api/v1/admin/vms/${id}/start`,
      {},
      {
        headers: bodyHeader,
      }
    )
  );
};

export const stopVM = (id) => {
  return $observable(
    Axios.post(
      `api/v1/admin/vms/${id}/stop`,
      {},
      {
        headers: bodyHeader,
      }
    )
  );
};

export const suspendVM = (id) => {
  return $observable(
    Axios.post(
      `api/v1/admin/vms/${id}/suspend`,
      {},
      {
        headers: bodyHeader,
      }
    )
  );
};
