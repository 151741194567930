import Axios from "axios";
import {
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getOrganizations = () => {
  return $observable(Axios.get("api/v1/admin/organizations"));
};

export const getOrganization = (id) => {
  return $observable(Axios.get(`api/v1/admin/organizations/${id}`));
};

export const deleteOrganization = (id) => {
  return $observable(Axios.delete(`api/v1/admin/organizations/${id}`));
};

export const addOrganization = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/organizations`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyOrganization = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/organizations`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
