import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyReceipt, addReceipt } from "../../domain/apis/receiptService";
import _ from "lodash";
import { useEffect } from "react";
import { FormUIItem } from '../commons';

function ReceiptModifyComponent({ show, handleClose, data }) {

  var formUI = {};
  useEffect(() => {
    if (!data) {
      formUI = {

      };
    } else {
      formUI = data;
    }
  }, [data]);

  const handleUpdateForm = (key, value) => {
    formUI[key] = value;
  };

  const onModify = () => {
    if (data) {
      modifyReceipt(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addReceipt(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa hóa đơn</Modal.Title>}
            {!data && <Modal.Title>Thêm hóa đơn</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormUIItem
                label={"Tên"}
                code={"name"}
                required
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm} />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReceiptModifyComponent;
